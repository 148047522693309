<template>
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5C8.42355 5 6.33576 7.23808 6.33576 10M11 5L3.8745e-07 5M11 5C8.42355 5 6.33576 2.76191 6.33576 -2.04632e-07"
      stroke-miterlimit="10"
    />
  </svg>
</template>
